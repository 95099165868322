import React from 'react';
import styled from 'styled-components';

import { Tabs, Tab } from '@material-ui/core';

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default ({ value, setValue, list }: any) => {
  // const [value, setValue] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      variant="fullWidth"
      indicatorColor="primary"
      aria-label="Days of week"
    >
      {list.map((value: any) => (
        <Tab key={value.tabId} label={value.label} {...a11yProps(value.tabId)}>
          yrdyr d
        </Tab>
      ))}
    </Tabs>
  );
};
