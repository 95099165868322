import { createMuiTheme } from '@material-ui/core/styles';
// import purple from '@material-ui/core/colors/purple';
// import green from '@material-ui/core/colors/green';
import { green, orange, purple } from '@material-ui/core/colors';

export default createMuiTheme({
  palette: {
    // type: 'dark',
    primary: {
      // main: purple[500],
      main: orange[800],
    },
    secondary: {
      main: green[500],
    },
  },
  overrides: {
    MuiDialog: {
      paper: {
        backgroundColor: '#fff6e1'
        // backgroundColor: '#00000090'
      }
    },
    MuiBottomNavigation: {
      root: {
        
      }
    }
  }

});
