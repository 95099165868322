import React from 'react';
import { ThemeProvider } from '@material-ui/styles';

// import { useSelector } from 'react-redux';
// import Auth from '../../services/firebase/Auth';

import AppSnackbar from '../app/AppSnackbar';
import AppContainer from '../../modules/shared/components/AppContainer';
// import AppNavigationTop from '../../modules/shared/components/AppNavigationTop';
import AppNavigationBotton from '../../modules/shared/components/AppNavigationBotton';

import OrangeDarkTheme from '../../modules/shared/themes/OrangeDarkTheme';

export default ({ history, children }: any) => {
  // const auth = useSelector((state: any) => state.auth);

  // useEffect(() => {
  //   async function verifyAuth() {
  //     let user = await Auth.user();
  //     console.log('LAYOUTPRIVATE', user);

  //     if (!user && !auth.uid) {
  //       history.push('/');
  //     }
  //   }

  //   verifyAuth();
  // }, []);

  return (
    <ThemeProvider theme={OrangeDarkTheme}>
      <AppContainer color="orange">
        <>
          {children}
          <AppNavigationBotton />
          <AppSnackbar />
        </>
      </AppContainer>
    </ThemeProvider>
  );
};
