import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

import Container from '@material-ui/core/Container';

import Service from '../../../services/firebase/Tasks';

import TabDaysOfWeek from '../components/TabDaysOfWeek';
import ListItem from '../../auth-chat/components/ListItem';
import TabBottomTasks from '../components/TabBottomTasks';
import TaskWrite from './TaskWrite';

// import Types from '../../../redux/sagas/types';

import List from '../components/List';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TabDaysMonth from '../components/TabDaysMonth';
import ToolbarInfo from '../components/ToolbarInfo';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Tasks() {
  // const router = useRouter();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = React.useState(false);
  const [dialogWriteOpen, setDialogWriteOpen] = React.useState(false);
  const auth = useSelector((state: any) => state.auth);
  const [list, setList]: any = React.useState([]);
  const [data, setData] = React.useState({});
  const [tabId, setTabId] = React.useState(0);
  // const list = useSelector((state: any) => state.tasks.allId);

  async function load() {
    let data = await Service.getAll({ userId: auth.id });
    setList(order(data));
    // updateList(data);
    // const byId = Service.getAll({ userId: auth.userId });
    // console.log(byId);
    // if (list.length === 0) {
    //   dispatch({
    //     type: Types.TASKS.LOAD,
    //     payload: { page, userId: auth.id },
    //   });
    // }
  }

  React.useEffect(() => {
    load();
  }, []);

  async function handleDelete(data: any) {
    await Service.delete(data.id);
    setList(list.filter((value: any) => value.id !== data.id));
  }

  async function handleDialog(status: boolean, data: any = {}) {
    if (!data || !data.id) {
      if (getWeekDayByTabId(tabId) === 8 || getWeekDayByTabId(tabId) === 7) {
        data.weekDay = '';
      } else {
        data.weekDay = getWeekDayByTabId(tabId);
      }
    }
    console.log(data);
    setDialogWriteOpen(status);
    setData(data);
  }

  //adicionar execao caso de falha.
  function handleCheck(data: any) {
    data.done = !data.done;
    console.log('Começous', data.text);
    Service.save(data, data.id).then((result) => {
      updateList(result.data);
    });
  }

  function onSubmit(params: any) {
    if (params.data) {
      updateList(params.data);
    }
  }

  const uniq = (data: any) => _.uniqBy([...[data], ...list], 'id');
  const order = (data: any) =>
    _.orderBy(data, ['done', 'priority', 'createdAt'], ['asc', 'asc', 'asc']);

  function updateList(data: any) {
    setList(order(uniq(data)));
  }

  const daysOfWeek = [
    { tabId: 0, weekDay: 7, name: 'Todos', label: 'Todos' },
    { tabId: 1, weekDay: 8, name: 'À fazer', label: 'À fazer' },
    { tabId: 2, weekDay: 9, name: 'Fazendo', label: 'Fazendo' },
    { tabId: 3, weekDay: 10, name: 'Feito', label: 'Feito' },
    { tabId: 4, weekDay: 1, name: 'Segunda', label: 'S' },
    { tabId: 5, weekDay: 2, name: 'Terça', label: 'T' },
    { tabId: 6, weekDay: 3, name: 'Quarta', label: 'Q' },
    { tabId: 7, weekDay: 4, name: 'Quinta', label: 'Q' },
    { tabId: 8, weekDay: 5, name: 'Sexta', label: 'S' },
    { tabId: 9, weekDay: 6, name: 'Sábado', label: 'S' },
    { tabId: 10, weekDay: 0, name: 'Domingo', label: 'D' },
  ];

  const listByWeekDay = (day: any) => {
    if (day === 7) return list;
    if (day === 8)
      return list.filter(
        (value: any) => value.weekDay === '' && value.done === false
      );
    if (day === 9)
      return list.filter(
        (value: any) => value.weekDay !== '' && value.done === false
      );
    if (day === 10) return list.filter((value: any) => value.done === true);
    return list.filter((value: any) => value.weekDay === day);
  };
  const getWeekDayByTabId = (tabId: any) =>
    daysOfWeek.filter((value) => value.tabId === tabId)[0].weekDay;

  const handleFocus = () => history.push('/focus');

  return (
    <Container maxWidth="md" style={{ display: 'flex' }}>
      <ContainerTasks>
        <ToolbarInfo />
        <TabDaysOfWeek value={tabId} setValue={setTabId} list={daysOfWeek} />
        {/* <TabDaysMonth /> */}
        {daysOfWeek.map((value) => (
          <TabPanel key={value.tabId} value={tabId} index={value.tabId}>
            <List
              data={listByWeekDay(getWeekDayByTabId(value.tabId))}
              handleDialog={handleDialog}
              handleDelete={handleDelete}
              handleCheck={handleCheck}
              handleFocus={handleFocus}
            />
          </TabPanel>
        ))}
        <TabBottomTasks openWrite={() => handleDialog(true, {})} />
      </ContainerTasks>
      <TaskWrite
        open={dialogWriteOpen}
        data={data}
        handleOpen={handleDialog}
        onSubmit={onSubmit}
      />
    </Container>
  );
}

const ContainerList = styled.div`
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%; */
`;
const ContainerTasks = styled.div`
  min-width: 300px;
  width: 100%;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%; */
`;
