import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider } from '@material-ui/core/styles';

import { useSelector } from 'react-redux';
// import dayjs from 'dayjs'
// import weekday from 'dayjs/plugin/weekday'

// dayjs.extend(weekday)

import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import { TransitionProps } from '@material-ui/core/transitions';
import Slide from '@material-ui/core/Slide';

import OrangeTheme from '../../shared/themes/OrangeTheme';

import Service from '../../../services/firebase/Tasks';
// const styles = (theme: Theme) =>
//   createStyles({
//     root: {
//       margin: 0,
//       padding: theme.spacing(2),
//     },
//     closeButton: {
//       position: 'absolute',
//       right: theme.spacing(1),
//       top: theme.spacing(1),
//       color: theme.palette.grey[500],
//     },
//     formControl: {
//       margin: theme.spacing(1),
//       minWidth: 120,
//     },
//   });

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    dialog: {
      paper: {
        backgroundColor: '#000',
      },
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TaskWrite({ open, handleOpen, onSubmit, data }: any) {
  const classes = useStyles();
  const auth = useSelector((state: any) => state.auth);
  const defaultFormValue = {
    id: '',
    userId: auth.id,
    text: '',
    weekDay: '',
    priority: 3,
    done: false,
  };
  const [form, setForm]: any = useState(defaultFormValue);

  useEffect(() => {
    if (open === true && data) {
      // setForm(data.id ? data : defaultFormValue );
      setForm({ ...defaultFormValue, ...data });
    } else {
      setForm(defaultFormValue);
    }
  }, [open]);

  function closeDialog() {
    setForm(defaultFormValue);
    handleOpen(false);
  }

  async function handleSubmit() {
    const result = await Service.save(form, form.id);
    setForm(defaultFormValue);
    handleOpen(false);
    onSubmit(result);
  }

  function handleFormValue(params: any) {
    setForm({ ...form, ...params });
  }

  return (
    <div>
      <ThemeProvider theme={OrangeTheme}>
        <Dialog
          fullWidth
          className={classes.dialog}
          TransitionComponent={Transition}
          open={open}
          // onClose={closeDialog}
          aria-labelledby="form-dialog-title"
          disableBackdropClick
        >
          {/* <DialogTitle id="form-dialog-title">Escrever</DialogTitle> */}
          {/* <DialogTitle id="customized-dialog-title" onClose={handleClose}></DialogTitle> */}
          <DialogContent>
            {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText> */}

            <FormControl
              className={classes.formControl}
              style={{ minWidth: '120' }}
            >
              <InputLabel id="demo-simple-select-label">O dia...</InputLabel>
              <Select
                autoWidth
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={form.weekDay}
                onChange={({ target }) =>
                  handleFormValue({ weekDay: target.value })
                }
              >
                <MenuItem value="">
                  <em>A fazer...</em>
                </MenuItem>
                <MenuItem value={1}>Segunda</MenuItem>
                <MenuItem value={2}>Terça</MenuItem>
                <MenuItem value={3}>Quarta</MenuItem>
                <MenuItem value={4}>Quinta</MenuItem>
                <MenuItem value={5}>Sexta</MenuItem>
                <MenuItem value={6}>Sábado</MenuItem>
                <MenuItem value={0}>Domingo</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              className={classes.formControl}
              style={{ minWidth: '120' }}
            >
              <InputLabel id="demo-simple-select-label">
                Prioridade...
              </InputLabel>
              <Select
                autoWidth
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={form.priority}
                onChange={({ target }) =>
                  handleFormValue({ priority: target.value })
                }
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
              </Select>
            </FormControl>
            <TextField
              autoFocus
              value={form.text}
              onChange={({ target }) => handleFormValue({ text: target.value })}
              margin="dense"
              id="text"
              label="Escreva aqui..."
              type="text"
              fullWidth
              multiline
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleSubmit} color="primary" variant="contained">
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  );
}
