import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Edit from '@material-ui/icons/Edit';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

export default ({ openWrite }: any) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.root}
    >
      {/* <BottomNavigationAction label="Recents" icon={<RestoreIcon />} /> */}
      {/* <BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} /> */}
      <BottomNavigationAction
        label="Escrever"
        icon={<Edit />}
        onClick={openWrite}
      />
    </BottomNavigation>
  );
};
