import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';
import CheckBoxOutlineBlank from '@material-ui/icons/RadioButtonUncheckedRounded';
import Check from '@material-ui/icons/Check';
import Icon from '@material-ui/core/Icon';
import ListSubheader from '@material-ui/core/ListSubheader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      // maxWidth: 360,
      backgroundColor: '#00000030',
      // backgroundColor: theme.palette.background.paper,
      // width: '100%',
      // maxWidth: 360,
      // backgroundColor: theme.palette.background.paper,
      position: 'relative',
      overflow: 'auto',
      // maxHeight: 300,
    },
    listSection: {
      backgroundColor: 'inherit',
      // backgroundColor: '#00000030',
    },
    subhead: {
      // backgroundColor: 'inherit',
      backgroundColor: '#00000080',
    },
    ul: {
      backgroundColor: 'inherit',
      padding: 0,
    },
    message: {
      textAlign: 'center',
    },
    textDone: {
      textDecoration: 'line-through',
    },
    textDefault: {},
  })
);

export default function ListComponent({
  data,
  handleDialog,
  handleDelete,
  handleCheck,
  handleFocus,
}: any) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([0]);

  const handleToggle = (data: any) => () => {
    const currentIndex = checked.indexOf(data.id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(data.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);

    handleDialog(true, data);
  };

  return (
    <List className={classes.root}>
      {data.length === 0 && (
        <div className={classes.message}>Sem tarefas...</div>
      )}

      {data.map((value: any, index: any) => {
        const labelId = `checkbox-list-label-${value.id}`;

        return (
          <ListItem
            key={index}
            role={undefined}
            dense
            button
            onClick={handleToggle(value)}
          >
            {/* <ListItemIcon>
              <Checkbox
                edge="start"
                checked={checked.indexOf(value.id) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon> */}
            {/* <ListItemIcon>
                <Icon>check</Icon> */}
            {/* <Icon>check_circle</Icon> */}
            {/* <Icon>circle_outline</Icon> */}
            {/* </ListItemIcon> */}

            <ListItemText
              id={labelId}
              primary={value.text}
              className={value.done ? classes.textDone : classes.textDefault}
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="comments"
                onClick={() => handleDelete(value)}
              >
                <Icon>delete</Icon>
              </IconButton>
              <IconButton
                edge="end"
                aria-label="comments"
                onClick={() => handleFocus()}
              >
                <Icon>av_timer</Icon>
              </IconButton>
              <IconButton
                edge="end"
                aria-label="comments"
                onClick={() => handleCheck(value)}
              >
                {/* <Icon>{value.done ? 'check' : 'checkbox_marked_circle'}</Icon> */}
                {value.done ? <Check /> : <CheckBoxOutlineBlank />}
              </IconButton>
              {/* <IconButton edge="end" aria-label="comments">
                <CommentIcon />
              </IconButton> */}
              {/* <IconButton edge="end" aria-label="comments">
                <Icon>edit</Icon>
              </IconButton> */}
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
    // <List className={classes.root} subheader={<li />}>
    //   {[0, 1, 2, 3, 4].map((sectionId) => (
    //     <li key={`section-${sectionId}`} className={classes.listSection}>
    //       <ul className={classes.ul}>
    //         <ListSubheader className={classes.subhead}>{`I'm sticky ${sectionId}`}</ListSubheader>
    //         {[0, 1, 2].map((item) => (
    //           <ListItem key={`item-${sectionId}-${item}`}>
    //             <ListItemText primary={`Item ${item}`} />
    //           </ListItem>
    //         ))}
    //       </ul>
    //     </li>
    //   ))}
    // </List>
  );
}
