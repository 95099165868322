import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/messaging';
// import 'firebase/database';

const firebaseConfig = {
  apiKey: 'AIzaSyB45oXJyBISbQKQJTCeE5z-qlgPYAW2yoc',
  authDomain: 'planus-app.firebaseapp.com',
  databaseURL: 'https://planus-app.firebaseio.com',
  projectId: 'planus-app',
  storageBucket: 'planus-app.appspot.com',
  messagingSenderId: '1050322315193',
  appId: '1:1050322315193:web:ecdca75309c04141ecb868',
  measurementId: 'G-Y12XNC4BEW',
};

// firebase.initializeApp(firebaseConfig);

// if (typeof window !== 'undefined' && !firebase.apps.length) {
if (!firebase.apps.length) {
  // firebase.analytics();
  firebase.initializeApp(firebaseConfig);

  firebase
    .firestore()
    .enablePersistence()
    .catch(function (err) {
      if (err.code == 'failed-precondition') {
        console.log('FIRESTORE - OFFILINE ERROR - ', err);
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
      } else if (err.code == 'unimplemented') {
        console.log('FIRESTORE - OFFILINE ERROR - ', err);
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
      }
    });
}

// const messaging = firebase.messaging();
const firestore = firebase.firestore();
// firestore.settings({ timestampsInSnapshots: true });

export default firebase;
export { firestore };

// messaging.onMessage((params) => {
//   console.log(params);
// });

// messaging.usePublicVapidKey(
//   // Project Settings => Cloud Messaging => Web Push certificates
//   'BI9ygYrK_8rjg9lsAkuzJljbdDCTzHndw-E3rYq7h5zxMEIDDOjdcp13eJtW6koiUUvyGaWyLsIcnVd50mfVmOo'
// );

// const subscribe = () => {

// }
