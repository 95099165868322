import React from 'react';
import styled from 'styled-components';
import dayjs from '../../../services/Moment';

export default function ToolbarInfo() {
  const date = dayjs(new Date());

  return (
    <Container>
      Hoje é {date.format('dddd').toLowerCase()},{' '}
      {date.format('d [de] MMMM [de] YYYY')} | Semana {date.week()} | Dia{' '}
      {date.dayOfYear()}
    </Container>
  );
}

const Container = styled.div`
  background-color: #00000090;
  font-size: 16px;
  padding: 5px;
  /* height: 100%; */
  /* height: 100px;
  width: 100px; */
`;
