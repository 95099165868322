import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';

// import AppBar from '../modules/shared/components/AppBar';

import AppContainer from '../../shared/components/AppContainer';
import Container from '@material-ui/core/Container';
import Tasks from '../../tasks/containers/Tasks';

import Auth from '../../../services/firebase/Auth';
import Types from '../../../redux/reducers/types';

export default function Home() {
  const history = useHistory();
  const dispatch = useDispatch();
  // const router = useRouter();
  const auth = useSelector((state: any) => state.auth);

  useEffect(() => {
    console.log('Aeeeee');
    if (!auth.id) {
      console.log('redirect');
      history.push('/');
    }
  }, []);

  function handlerLogout() {
    dispatch({ type: Types.AUTH.REFRESH });
    Auth.logout();
    localStorage.clear();
    // router.push('/');
  }

  return (
    <>
      {/* <Head>
        <title>Tarefas | Planus</title>
        <link rel="icon" href="/favicon.ico" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
      </Head> */}
      <main>
        <AppContainer color="orange">
          <>
            {/* <AppBar handlerLogout={handlerLogout}/> */}
            <Tasks />
          </>
        </AppContainer>
      </main>
    </>
  );
}
