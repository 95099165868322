import React from 'react';

import AppContainer from '../../shared/components/AppContainer';
import Container from '@material-ui/core/Container';

import Chat from '../containers/Chat';
export default () => {
  return (
    <AppContainer color="orange">
      <Container maxWidth="sm" style={{ display: 'flex', height: '100vh' }}>
        <Chat />
      </Container>
    </AppContainer>
  );
};
