import { createMuiTheme } from '@material-ui/core/styles';
// import purple from '@material-ui/core/colors/purple';
// import green from '@material-ui/core/colors/green';
import { green, orange, purple } from '@material-ui/core/colors';

export default createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      // main: purple[500],
      main: orange[400],
    },
    secondary: {
      main: green[500],
    },
  },
  overrides: {
    MuiDialog: {
      paper: {
        backgroundColor: '#fff6e1',
      },
    },
    MuiBottomNavigation: {
      root: {
        backgroundColor: '#00000030',
      },
    },
    MuiBottomNavigationAction: {
      label: {
        color: '#FFF',
      },
      wrapper: {
        color: '#FFF',
      },
    },
    MuiTabs: {
      root: {
        backgroundColor: '#00000090',
        minWidth: '30',
      },
    },
  },
});
